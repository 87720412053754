import React, { useRef, useState } from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Hover from "../components/hover";
import Footer from "../components/footer";
import { Link } from "gatsby";
import VideoBackground from "../components/videoBackground";
import SwiperCore, { Parallax, Navigation, Scrollbar, A11y, Mousewheel } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import VideoMockup from "../components/videoMockup";
import ArrowDown from "../components/arrowDown";
import AnimatedText from '../components/animatedText';
import Seo from "../components/seo"
// require('swiper/components/pagination/pagination.scss');



const Wrapper = styled.div`


h2{
    position:absolute;
z-index:1;
    font-size: 7rem;
    letter-spacing: 0.1rem;
    text-transform:uppercase;
}

@media (max-width:1024px){
    h2{
    font-size: 12vw;
}}


@media (max-width:375px){
    h2{  font-size: 12vw;
}}


.cover {position:absolute;
z-index:1;}

.cover p{
    text-shadow: 0px 4px 12px rgb(0 0 0 / 50%);
}

.swiper{
    height: 100vh;
}

.swiper-pagination-bullet {
    background: #fff;
    opacity: 0.4;
}

.swiper-container-vertical > .swiper-pagination-bullets {
    right: 2rem;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: #fff;
}

.swiper-container {
  width: 100vw;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;
  height: var(--app-height);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  transition-timing-function: ease;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-bgimg{
    transition: all 1s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
}

.flux{
    height:100vh;
}

video{
    height:100vh;
}
`


// install Swiper modules
SwiperCore.use([Parallax, Navigation, Scrollbar, A11y, Mousewheel]);


// markup
const Services = () => {
    const ref = useRef(null);
    const [footerReach, setFooterReach] = useState(false);
    const revealRefs = useRef([]);

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    const videoList = {
        popupstore: {
            text: "Virtual Pop-Up Store",
            slug: "virtual-popup-store",
            video: "popupstore"
        },
        virtualshowroom: {
            text: "Virtual Showroom",
            slug: "virtual-showroom",
            video: "virtualshowroom"
        },
        virtualevents: {
            text: "Digital Events",
            slug: "digital-events",
            video: "digitalevents"
        },
        virtualfashion: {
            text: "Virtual Fashion",
            slug: "virtual-fashion",
            video: "virtualfashion"
        },
        phygitalexperiences: {
            text: "Phygital Experiences",
            slug: "phygital-experiences",
            video: "phygitalexperiences"
        }
    }

    const videoListArray = [];
    for (let key in videoList) {
        videoListArray.push({
            id: key,
            config: videoList[key]
        });
    }

    function playVideo(index, previous) {          // Show loading animation.
        const videoElem = revealRefs.current[index];
        var videoPlay = videoElem.querySelector("video");

        const videoElemPause = revealRefs.current[previous];
        videoElemPause.querySelector("video").pause();

        var playPromise = videoPlay.play();
        ScrollTrigger.refresh()
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                // Automatic playback started!
                // Show playing UI.
            })
                .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                });
        }
    }

    return (
        <>
            <div ref={ref}>
                <Layout>
                    <Seo title="Services - Vertual" />
                    <Hover type="">
                        <main data-scroll data-scroll-id="update" data-scroll-call="stopScroll">
                            <Wrapper>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    direction={'vertical'}
                                    navigation={{
                                        nextEl: '.review-swiper-button-next',
                                    }}
                                    mousewheel
                                    parallax
                                    speed={1000}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        768: {
                                            pagination: {
                                                "clickable": true
                                            }
                                        },
                                    }}
                                    scrollbar={{ draggable: true }}
                                    onSlideChange={(swiper) => {
                                        playVideo(swiper.activeIndex, swiper.previousIndex)

                                        if (swiper.activeIndex === swiper.slides.length - 2) {
                                            setFooterReach(false);
                                        }
                                    }}
                                    onProgress={(swiper) => {
                                        for (let i = 0; i < swiper.slides.length; i++) {
                                            let interleaveOffset = 0.5;
                                            let slideProgress = swiper.slides[i].progress,
                                                innerOffset = swiper.height * interleaveOffset,
                                                innerTranslate = slideProgress * innerOffset;
                                            swiper.slides[i].querySelector(".slide-bgimg").style.transform =
                                                "translateY(" + innerTranslate + "px)";
                                        }
                                    }}
                                    onReachEnd={(swiper) => {
                                        setFooterReach(true);
                                    }}
                                >
                                    <SwiperSlide><ArrowDown /><div className="cover"><AnimatedText tag="h1-main">Services</AnimatedText>
                                        {/* <p>We create memorable experiences that mix together brand, content, architecture and digital with unlimited range of customizations and disperse it across all channels.</p> */}
                                    </div><div className="slide-bgimg" ref={addToRefs}><VideoBackground video={"videoServices"} /></div></SwiperSlide>
                                    {videoListArray.map(listElement => (
                                        <SwiperSlide><div className="review-swiper-button-next"><ArrowDown services /></div><h2 data-swiper-parallax="-30%" data-swiper-parallax-opacity="0.1"><Link to={"/" + listElement.config.slug}>{listElement.config.text}</Link></h2><Link to={"/" + listElement.config.slug}><div data-scroll data-scroll-call={listElement.config.slug} className="slide-bgimg " ref={addToRefs} ><VideoMockup video={listElement.config.video} fullpage /></div></Link></SwiperSlide>
                                    ))}
                                    <SwiperSlide><div className="slide-bgimg" ref={addToRefs}><Footer page="about" services={footerReach} /></div></SwiperSlide>
                                </Swiper>
                            </Wrapper>
                        </main>
                    </Hover>
                </Layout>
            </div>
        </>
    )
}

export default Services


